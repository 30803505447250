import React from 'react';
import { css } from '@emotion/react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import OrganizationEmptyError from '../errors/organization-empty';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const LoginPageV2 = () => {
  const history = useHistory();
  const { login, localToken } = React.useContext(AuthContext);
  const emailRef = React.useRef<HTMLInputElement | null>(null);
  const [showPassword, setShowPassword] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: false,
      invalid: false,
    },
    onSubmit: async ({ email, password }, helpers) => {
      try {
        helpers.setSubmitting(true);
        const { active } = await login(email, password);
        helpers.setSubmitting(false);
        if (!active) {
          return history.replace('/register/finish');
        }

        return history.replace('/');
      } catch (error) {
        helpers.setSubmitting(false);
        if (error instanceof OrganizationEmptyError) {
          history.push('/register/company');
        } else {
          if (emailRef.current) {
            emailRef.current.focus();
          }
          helpers.setFieldError('invalid', 'Wrong email and password combination');
        }
      }
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email('Invalid email format').required('Email address is required'),
      password: Yup.string().required('Password is required'),
    }),
    isInitialValid: false,
  });

  console.log(formik);

  if (localToken) {
    return <Redirect to="/" />;
  }

  return (
    <div className="flex h-screen w-screen items-stretch">
      <section
        className="flex flex-col bg-white px-14 md:px-36"
        css={css`
          @media only screen and (min-width: 1024px) {
            flex-basis: 636px;
          }
        `}
      >
        <div className="relative flex flex-col justify-center text-center lg:text-left flex-1 py-14">
          <div className="flex justify-center lg:justify-start pb-14">
            <img
              className="w-36 md:w-32"
              src={process.env.PUBLIC_URL + '/assets/qore-logo.svg'}
              alt="Qore Logo"
            />
          </div>
          <h1 className="text-2xl font-semibold mb-0">Get Started</h1>
          <p className="text-sm text-gray-500 mb-10">Hello there and welcome back!</p>
          <form className="flex flex-col" onSubmit={formik.handleSubmit}>
            {formik.errors.invalid ? (
              <p className="bg-red-200 p-2 rounded-md flex items-center">
                <i className="bx bx-info-circle text-base" />
                &ensp;{formik.errors.invalid}
              </p>
            ) : null}
            <div className="flex flex-col">
              <label htmlFor="email" className="label-required text-md text-left mb-2">
                Email
              </label>
              <div className="relative text-gray-600 focus-within:text-gray-400 transition duration-300 ease-out">
                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-lg">
                  <i className="bx bx-user-circle"></i>
                </span>
                <input
                  ref={emailRef}
                  name="email"
                  className="form-input-control form-input-control--icon-left "
                  placeholder="Ex: admin@qore.com"
                  autoComplete="off"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
              </div>
              {formik.errors.email && (
                <p className="m-0 text-xs py-1 text-red-600">{formik.errors.email}</p>
              )}
            </div>
            <div className="flex flex-col pt-4">
              <label htmlFor="email" className="label-required text-md text-left mb-2">
                Password
              </label>
              <div className="relative text-gray-600 focus-within:text-gray-400 transition duration-300 ease-out">
                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-lg">
                  <i className="bx bx-lock"></i>
                </span>
                <input
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  className="form-input-control form-input-control--icon-left form-input-control--icon-right "
                  placeholder="Min. 8 Character"
                  autoComplete="off"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.password}
                />
                <button
                  type="button"
                  onClick={() => setShowPassword((show) => !show)}
                  className="absolute inset-y-0 right-0 flex items-center justify-center px-3 text-lg focus:outline-none"
                >
                  <i className={`text-gray-700 bx ${showPassword ? 'bx-hide' : 'bx-show'}`}></i>
                </button>
              </div>
              {formik.errors.password && (
                <p className="m-0 text-xs py-1 text-red-600">{formik.errors.password}</p>
              )}
            </div>
            <div className="flex justify-between pt-4">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  name="remember"
                  className="mr-2"
                  onChange={formik.handleChange}
                  checked={formik.values.remember}
                />
                Remember Me
              </label>
              <div>
                <Link to="/auth/forgot-password">Forgot Password</Link>
              </div>
            </div>
            <button
              type="submit"
              disabled={!formik.isValid || formik.isSubmitting}
              className="bg-blue-600 text-white text-base font-semibold p-3 hover:bg-blue-500 disabled:bg-gray-300 disabled:text-gray-500 rounded-md mt-8 transition duration-300 ease-in-out"
            >
              {formik.isSubmitting ? 'Signing in...' : 'Sign In With Email'}
            </button>
            <p className="text-center lg:text-left mt-6">
              Not registered yet?&ensp;
              <Link to="/register/form">
                <strong>Create An Account</strong>
              </Link>
            </p>
          </form>
        </div>
        <p className="pt-25 mb-10 text-center lg:text-left text-gray-500">
          By signing in, you agree with our <Link to="#">Terms &amp; Conditions</Link> and{' '}
          <Link to="#">Privacy Policy</Link>
        </p>
      </section>
      <section
        className="bg-blue-100 fixed right-0 h-full flex-1 hidden lg:block bg-gradient-to-b from-blue-700 to-blue-400 overflow-hidden"
        css={css`
          width: calc(100% - 636px);
          flex-basis: calc(100% - 636px);
        `}
      >
        <div
          className="flex flex-col justify-center items-center h-full w-full px-10  "
          css={css`
            background-image: url('/assets/logo-pattern.svg');
            background-repeat: repeat-x;
            background-position: -72px 0;
          `}
        >
          <img className="max-w-full" src="/assets/illustration-1.svg" alt="Qore Platform" />
          <div
            className="text-center leading-snug"
            css={css`
              max-width: 456px;
            `}
          >
            <h1 className="text-3xl mb-6 text-white font-semibold">
              Good colaboration tool for digitalizing your business
            </h1>
            <p className="text-gray-200">
              Qore provides an environment for business analysts, developers, and other stakeholders
              to collaborate in building applications that are agile and flexible.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LoginPageV2;
