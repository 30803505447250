import React from 'react';
import QoreUserClient, { QoreUser } from '../sdk/user';

const AuthContext = React.createContext<
  QoreUser & {
    activeEmail: string | undefined;
    logout: () => void;
    localToken: string | null;
    tempToken: string | undefined;
    resendVerificationCode: () => void;
  }
>({
  register: async () => {},
  login: async () => ({ token: '', active: false }),
  verify: async () => {},
  createOrganization: async () => '',
  organizations: async () => [],
  organization: async () => undefined,
  projects: async () => [],
  user: () => undefined,
  logout: () => undefined,
  activeEmail: undefined,
  localToken: null,
  tempToken: '',
  resendVerificationCode: async () => {},
});

const useAuthContext = () => {
  const [client] = React.useState<ReturnType<typeof QoreUserClient>>(QoreUserClient());
  const [tempToken, setTempToken] = React.useState('');
  const [token, setToken] = React.useState(client.user()?.token || localStorage.getItem('token'));
  const [activeEmail, setActiveEmail] = React.useState<string>();
  const resendVerificationCode = async () => client.resendCode(tempToken);

  const login = async (email: string, password: string) => {
    setActiveEmail(email);
    const result = await client.login(email, password);
    if (!result.active) {
      setTempToken('Bearer ' + result.token);
      return result;
    }
    localStorage.setItem('token', client.user().token);
    setToken(client.user().token);
    return result;
  };

  const register = async (params: { email: string; password: string }) => {
    await client.register({ email: params.email, password: params.password });
    let email = params.email;
    setActiveEmail(email);
  };

  const verify = async (email: string, activationCode: string) => {
    await client.verify(email, activationCode);
    setToken(tempToken);
    localStorage.setItem('token', tempToken);
  };

  const createOrganization = async (params: {
    name: string;
    category: string;
    size: string;
    subdomain: string;
  }) => {
    var param = {
      name: params.name,
      category: params.category,
      size: params.size,
      subdomain: params.subdomain,
    };
    const response = await client.createOrganization(param);
    return response;
  };

  const logout = () => {
    client.logout();
    localStorage.clear();
    setToken(null);
  };

  React.useEffect(() => {
    if (!token) return;
    client.setToken(token);
  }, [token, client]);

  return {
    ...client,
    localToken: token,
    login,
    register,
    verify,
    createOrganization,
    activeEmail,
    logout,
    tempToken,
    resendVerificationCode,
  };
};

const AuthProvider = (props: { children: React.ReactNode }) => {
  const { Provider } = AuthContext;
  const hookValue = useAuthContext();
  return <Provider value={hookValue}>{props.children}</Provider>;
};

export { AuthContext, AuthProvider };
