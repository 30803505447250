import { Organization } from 'app/modules/sdk/user';
import React from 'react';

type InitialValueProps = {
  selectOrganization: (id: string | React.ReactText) => void;
  selectedOrganization?: Organization;
  organizations?: Organization[];
  setOrganizations: React.Dispatch<React.SetStateAction<Organization[] | undefined>>;
};

const initialValue: InitialValueProps = {
  selectOrganization: () => {},
  selectedOrganization: undefined,
  setOrganizations: () => {},
  organizations: undefined,
};

const OrganizationContext = React.createContext(initialValue);

const useOrganizationContext = () => {
  const [selectedOrganization, setOrganization] = React.useState<Organization>();
  const [organizations, setOrganizations] = React.useState<Organization[] | undefined>(undefined);
  const selectOrganization = React.useCallback(
    (id: string | React.ReactText) => {
      const filteredOrganization = organizations?.find((organization) => organization.id === id);
      setOrganization(filteredOrganization);
    },
    [organizations]
  );

  React.useEffect(() => {
    if (!organizations || selectedOrganization) return;

    const orgIdFromStorage = localStorage.getItem('orgId');

    const activeOrg = organizations.find((org) => org.id === orgIdFromStorage) || organizations[0];
    setOrganization(activeOrg);
  }, [organizations, selectedOrganization]);

  React.useEffect(() => {
    if (selectedOrganization) {
      localStorage.setItem('orgId', selectedOrganization.id);
    }
  }, [selectedOrganization]);

  return {
    selectOrganization,
    selectedOrganization,
    organizations,
    setOrganizations,
  };
};

const OrganizationProvider = (props: { children: React.ReactNode }) => {
  const { Provider } = OrganizationContext;
  const hookValue = useOrganizationContext();
  return <Provider value={hookValue}>{props.children}</Provider>;
};

export { OrganizationContext, OrganizationProvider };
